import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import { FaRupeeSign, FaLocationArrow } from "react-icons/fa";
import Modal from "./modal";
import emailjs from "emailjs-com";
const Hero = () => {
  const [State, setState] = useState(0);

  useEffect(() => {
    setState(window.innerWidth);
  }, [State]);

  const handelFormSubmit = (e) => {
    e.preventDefault();
    let client_name = e.target.elements.name.value;
    let client_email = e.target.elements.email.value;
    let phonenumber = e.target.elements.phone.value;
    var template_params = {
      Client_name: client_name,
      Client_email: client_email,
      Client_phone_number: phonenumber,
      Project_Name: "Adarsh Park Heights",
    };
    var service_id= "gmail";
    var template_id= "template_Be8dpfNK";
    var user_id = "user_dL8CNNb8AnMR28qE6oOlM";
    emailjs.send(
      service_id,
      template_id,
      template_params,
      user_id
     ).then(
      result => {
      console.log(result.text);
      alert("You will now be redirected.");
      window.location = "/download/";
      },
      error => {
        console.log(error.text)
      }
    )
  };
  return (
    <>
      <div css={one}>
        {State > 600 ? (
          <StaticImage
            src="../images/outdoor.jpg"
            alt="adarsh park heights"
            placeholder="blurred"
            layout="fullWidth"
            aspectRatio={7.5 / 3}
          />
        ) : (
          <StaticImage
            src="../images/outdoor.jpg"
            alt="adarsh park heights"
            placeholder="blurred"
            layout="constrained"
            aspectRatio={2 / 3}
          />
        )}
      </div>
      <div css={main}>
        <div css={mainOne}>
          <div className="info-box overflow-hidden">
            <span className="pro-status"></span>{" "}
            <span className="pro-title">Adarsh Park Heights</span>{" "}
            <span className="pro-add">
             Gunjur Village
            </span>{" "}
            <span className="pro-dev">by Adarsh Group</span>
            <ul className="pro-spec">
              <li>
                {" "}
                <b>Total Area</b> : 22.4 Acres
              </li>
              <li>
                {" "}
                <b>Units</b> : 787 Units{" "}
              </li>

              <li>
                {" "}
                <b>Possession</b> :May 2025{" "}
              </li>
              <li>
                {" "}
                <b></b> 1,2,3 BHK
              </li>
            </ul>
            <span
              className=""
              style={{
                fontSize: "15px",
                width: "100%",
                background: "transparent",
                fontWeight: "bold",
                textAlign: "center",
                color: "#fff",
              }}
            >
              <span
                style={{
                  color: "#fff",
                  display: "block",
                  backgroundColor: "#7f8380",
                  background:
                    "linear-gradient(-45deg, #4450a8db, #4450a8db, #4450a8db, #4450a8db)",
                  backgroundSize: "400% 400%",
                  WebkitAnimation: "Gradient 3s ease infinite",
                  MozAnimation: "Gradient 3s ease infinite",
                  animation: "Gradient 3s ease infinite",
                  fontSize: "15px",
                  padding: "5px",
                }}
              >
                Booking Open
              </span>
              {/* <span className="offer-bottom" style={{color: "#333", display: "block", borderBottom:"2px solid #d26c00", borderLeft:"2px solid #d26c00", borderRight:"2px solid #d26c00", padding: "4px"}}>
                        
                    </span>  */}
            </span>
            <br />
            <span className="pro-tag-line">
              Premium 1,2,3 BHK Apartments
            </span>{" "}
            <span className="pro-price">
              <FaRupeeSign />
              *._ _ Cr* <small>Onwards</small>
            </span>
            <Modal
              class="btn btn-info micro-form-btn enqModal effetMoveGradient effectScale"
              title="Enquire Now"
            />
            <br />
            <br />
            <span className="pro-rera">
              <b>RERA No</b> PRM/KA/RERA/1251/446/PR/210525/004163
            </span>
            <span className="pro-rera">
              <b>
                Please do visit our site to explore more about project detail.
              </b>
            </span>
            <br />
          </div>
        </div>
        <div>
          <div class="info-box-form overflow-hidden">
            <span class="pro-status"></span>{" "}
            <span class="pro-title1">Pre-Register here for Best Offers</span>
            <hr />
            <form
              name="price-popup"
              method="POST"
              role="form"
              class="form-side"
              onSubmit={handelFormSubmit}
            >
              <div class="form-group">
                <input
                  type="text"
                  name="name"
                  pattern="[a-zA-Z ]+"
                  required
                  class="form-control rounded-0 micro-form-field"
                  placeholder="Name"
                />
              </div>
              <div class="form-group">
                <input
                  name="phone"
                  class="form-control rounded-0 micro-form-field"
                  required
                  placeholder="Mobile Number"
                  minLength="10"
                  maxLength="20"
                  title="Must have 10 digit numbers"
                  pattern="^\d{10}$"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  name="email"
                  class="form-control rounded-0 micro-form-field"
                  required
                  placeholder="E-Mail Address"
                />
              </div>
              <br />
              <button
                type="submit"
                name="enq_formBtn"
                class="btn btn-info micro-form-btn effetMoveGradient"
                value="proceed"
              >
                Pre-Register Now
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;

const one = css`
  position: relative;
`;

const main = css`
  position: absolute;
  position: absolute;
  width: 100%;
  top: 10vh;
  display: grid;
  grid-template-columns: 6fr 6fr;
  @media (max-width: 600px) {
    display: block;
  }
`;

const mainOne = css`
  width: 30vw;
  top: 69px;
  left: 10px;
  background-color: #ffffffb8;
  color: #000;
  text-align: center;
  border-radius: 8px;
  padding: 0 10px;
  font-weight: 400;
  margin: 2vh;
  margin-left: 5vh;
  @media (max-width: 600px) {
    width: 77%;
  }
`;
