import React, { useEffect, useState } from "react";
import "./global.css";
import Container from "./Container";
import { css } from "@emotion/react";
import LogofromImagesFolder from "../images/icon.png";
import Title from "./TitleComponent";
import { UserStateContext } from "../pages";

const About = (props) => {
  return (
    <>
      <Container>
        <div css={main}>
          <img
            src={LogofromImagesFolder}
            alt="Adarsh Park heights"
            style={{ width: "195px", height: "80px" }}
          />
          <div>
            <Title
              title="Adarsh Park Heights"
              size="1.5vw"
              color="#000"
              case="uppercase"
            />
            <p>
              Beautiful greens views to dream for Luxurious residences.
              Recreational opportunities abound. <b>Adarsh Park Heights</b> is
              now open. This luxury residential complex, conveniently located in
              the lush area of Gunjur, Bangalore, provides quality 1, 2 & 3 BHK
              houses. To deliver opulent living among gorgeous greenery, every
              aspect of the project, from the location to the architectural
              sensibilities to the open areas, is designed to optimize the
              inhabitants' lifestyle.
              <br />
              <br />
              This gated community was designed by world-renowned architects,
              landscape developers, and interior decorators to make the most of
              every square inch of available space. Facilities have been
              designed to revolve around the open air, water, and earth. Not
              only do the facilities and landscaping adhere to global design
              standards, but the towers themselves have been constructed to
              maximize the benefits of natural light and air. We've made every
              effort to guarantee that even the most commonplace room in your
              <b>Adarsh Park Heights</b> house is created to perfection. Every
              flat has been designed with circulation, ventilation, as well as a
              happy family in mind, ensuring that you get your money's worth for
              every square foot you buy. <b>Adarsh Park Heights</b> will look
              forward to welcoming you home with elegance. With well-designed
              bedrooms that give tranquil views of the lush greenery or the
              metropolis, it won't be long until this luxurious hideaway
              relieves your daily tension and bassinets you to sleep.
              <br />
              <br />
              <b>Adarsh Park Heights</b>, conveniently located in Gunjur
              Village, Bangalore, offers the finest of both worlds. It links
              nicely to the city's comforts while still disconnecting from its
              tumult. <b>Adarsh Park Heights</b> has a variety of club amenities
              as well as huge communal zones to enhance residents' overall
              health and well-being. It has something for everybody in the
              family, from a sumptuous gymnasium to a relaxing spa. At{" "}
              <b>Adarsh Park Heights</b>, you have the option of selecting from
              a variety of clubhouse facilities. Based on your mood, you may
              work out in the gym or relax in the spa. Or you might simply relax
              by the tiny pool while admiring the magnificent nighttime sky.{" "}
              <b>Adarsh Developers</b> is responsible for various major
              developments that have shaped the cityscape of Bangalore's
              suburbs. Except for providing inhabitants with an improved
              lifestyle, we can proudly state that these improvements have
              increased the local value of the neighborhoods and converted them
              into desired living locations.
              <br />
              <br />
              <div>
                <b>
                  How can having a residence in Adarsh Park Heights alter your
                  lifestyle?
                </b>
                <br />
                It is the ideal combination of natural beauty and a luxurious
                lifestyle. A little about living in your own home may make life
                feel more comfortable and cozy. If you've been thinking about
                transitioning from renting to owning, now is the time to do so.
                The Adarsh Group has established itself with a respected name in
                real estate since its beginning in 1988.{" "}
                <b>Adarsh Park Heights</b> are among the latest luxury
                residential projects attempted by the Adarsh Group, an
                ISO-certified firm with over 30 years of expertise in creating
                various successful projects. The four distinct housing projects
                provide luxury settings unlike any other: each has its distinct
                voice, but they're also brilliantly interwoven so that you may
                enjoy them all as one unified community.{" "}
                <b>Adarsh Park Heights</b> are an amazing investment opportunity
                for anyone looking to secure their future. There are various
                amenities available, including a Gymnasium, a Swimming Pool, a
                Jogging Track, Table Tennis, a Basketball Court, a Café, a
                Multi-purpose Hall, as well as Billiards. The extensive list of
                amenities in this premium project ensures a variety of options
                for renewing, resting, and enjoying a luxurious lifestyle. Aside
                from the numerous amenities that enhance your living, there are
                also appropriate security mechanisms placed to assure the
                residents' protection 24 hours a day, seven days a week
              </div>
              <br/>
              <div>
                <b>
                  The benefits of living in a luxury villa in Adarsh park
                  heights
                </b>
                <ul style={{listStyleType:"decimal"}}>
                  <li>
                    <b>The Aesthetic</b> - A private garden signifies
                    refinement, luxury, and an elite lifestyle in an Adarsh
                    property.
                  </li>
                  <li>
                    <b>Exclusivity</b> - There seem to be no shared walls with
                    some other villas, and you have the option of limiting the
                    water use and other things that would not affect the other
                    residents in any way.
                  </li>
                  <li>
                    <b>Play</b> - Clubhouse provides an adequate area not just
                    for practicing sports such as tennis, squash, and
                    basketball, but also for socializing. It also has pools
                    (indoors), billiards tables, and a gym with weights
                    equipment.
                  </li>
                  <li>
                    <b>Amenities</b> - <b>Adarsh park heights</b> are the ideal spot to
                    call home. They provide elegant and premium services that
                    will fulfill all of your needs, as well as extras like 24/7
                    security monitoring and customized access cards for every
                    resident!
                  </li>
                </ul>
              </div>
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default About;

const main = css`
  text-align: center;
  p {
    text-align: justify;
    padding: 10px 20px;
    color: #000;
    letter-spacing: 0.02vw;
    line-height: 1.5;
    font-weight: 450;
    font-size: 16px;
  }
`;
